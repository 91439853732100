<template>
    <div class="home-proxy"></div>
</template>

<script>
export default {
    name: "HomeProxyView",

    beforeRouteLeave(to, from, next) {
        this.$store.commit('changeChatLayout', false);

        next()
    }
}
</script>

<style lang="scss">
.home-proxy {
    display: none;
}
</style>